import { PermissionsScope } from '@sbiz/business';
import {
  StorageType,
  getLocalStorageObject,
  getStorageItem,
  isInBrowser,
  setLocalStorageItem,
  setStorageItem,
} from '@sbiz/util-browser';
import { decodeJwt } from '@sbiz/util-common';

import { STORAGE_KEYS } from '..';
import { AccessTokenPayload, AuthUser, StorageEntries, StorageEntry } from './types';

const STORAGE_RETENTION = new Map<StorageType, string[]>([
  ['local', []],
  ['session', [STORAGE_KEYS.apiUrl, STORAGE_KEYS.redirectAfterLogin]],
]);

export function clearSiteData() {
  if (isInBrowser('Clearing site data')) {
    const savedSiteData = saveSiteData();

    sessionStorage.clear();
    localStorage.clear();

    restoreSiteData(savedSiteData);
    setLocalStorageItem(STORAGE_KEYS.siteDataClearance, new Date());
  }
}

function saveSiteData() {
  const savedData: StorageEntries = new Map([
    ['local', []],
    ['session', []],
  ]);

  for (const [type, keys] of STORAGE_RETENTION.entries()) {
    const savedEntries = savedData.get(type) as StorageEntry[];

    for (const key of keys) {
      const value = getStorageItem<string>(key, type);
      if (value) {
        savedEntries.push([key, value]);
      }
    }
  }

  return savedData;
}

function restoreSiteData(savedData: StorageEntries) {
  for (const [type, entries] of savedData.entries()) {
    for (const [key, value] of entries) {
      setStorageItem(key, value, type);
    }
  }
}

export function getAuthUser() {
  return getLocalStorageObject<AuthUser>(STORAGE_KEYS.authUser);
}

export function getCompanyId(authUser: AuthUser) {
  const { companyId, targetCompanyId } = authUser;

  if (isCompaniesAdministrator(authUser)) {
    return targetCompanyId;
  }

  return companyId;
}

export function isCompaniesAdministrator({ permissions }: AuthUser) {
  return Boolean(permissions?.companySelection?.read);
}

export function isEditor(user: AuthUser, scope: PermissionsScope) {
  return Boolean(user?.permissions?.[scope]?.write);
}

export function patchAuthUser(values: Partial<AuthUser>) {
  setLocalStorageItem(STORAGE_KEYS.authUser, values);
}

export function setAccessToken(accessToken: string) {
  const tokenPayload = decodeJwt<AccessTokenPayload>(accessToken);
  const {
    companies: [{ _id: companyId }],
  } = tokenPayload;
  const authUser: AuthUser = { ...tokenPayload, accessToken, companyId };
  setLocalStorageItem(STORAGE_KEYS.authUser, authUser);
}
