import { SvgIcon, SvgIconProps } from '@mui/material';

export function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0,-4)">
        <path d="m 15.305,17.5 c -1.965,0 -3.68,-1.01 -4.695,-2.685 L 11.955,14 c 0.74,1.225 1.955,1.925 3.345,1.925 1.39,0 2.605,-0.7 3.345,-1.925 l 1.345,0.815 C 18.975,16.49 17.265,17.5 15.295,17.5 Z M 8,15 H 4 v -2 h 4 z m 2,4 H 4 v -2 h 6 z m 14,5 H 0 V 8 H 24 Z M 2,22 H 22 V 10 H 2 Z" />
      </g>
    </SvgIcon>
  );
}
