import { SvgIcon, SvgIconProps } from '@mui/material';

export function AccountingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(-3)">
        <path d="M 18.415,0 H 6 V 24 H 24 V 5.585 Z M 18,2.415 21.585,6 H 18 Z M 8,22 V 2 h 8 v 6 h 6 v 14 z m 2,-12 h 10 v 2 H 10 Z m 0,4 h 10 v 2 H 10 Z m 0,4 h 10 v 2 H 10 Z" />
      </g>
    </SvgIcon>
  );
}
