import { useCallback } from 'react';

import { clearSiteData } from '../../session';
import { useClearCache } from './useClearCache';

export function useClearAppData() {
  const clearCache = useClearCache();

  return useCallback(() => {
    clearSiteData();
    clearCache(undefined, { isRevalidationDisabled: true });
  }, [clearCache]);
}
