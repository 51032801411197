import { InternationalizedPathname, LANGS } from '@sbiz/common';

export function parsePathname(pathname: string) {
  const route: InternationalizedPathname = { lang: null, pathname };

  for (const lang of LANGS) {
    const prefix = `/${lang}`;

    if (pathname === prefix || pathname.startsWith(`${prefix}/`)) {
      route.lang = lang;
      route.pathname = pathname.substring(prefix.length) || '/';
    }
  }

  return route;
}
