import { Metadata } from 'next';

import { APP_DISPLAY_NAME, PermissionsScope } from '@sbiz/business';
import { isKeyInObject } from '@sbiz/util-common';

import { getServerTranslation } from '../i18n/server';
import { Route, RouteOverride } from '../types';
import { TAB_PARAM_NAME } from './constants';
import { MetadataGeneratorProps, PAGE_TABS, PageName, PAGES, PageTabs } from './types';

export const PAGE_PERMISSION_SCOPES = getPagePermissionScopes();

export async function getMetadata(
  pageName: PageName,
  { params, searchParams }: MetadataGeneratorProps,
): Promise<Metadata> {
  const commonTitle = APP_DISPLAY_NAME;
  const prefix = `pages.${pageName}`;
  const tabParam = searchParams[TAB_PARAM_NAME];

  const { t } = await getServerTranslation(params.lang);

  let pageTitle = t(`${prefix}.title`, { defaultValue: '' });

  const tabIndex = tabParam ? Number(tabParam) : 0;
  const tabName = getPageTabs(pageName)[tabIndex]?.key;

  if (pageTitle && tabName) {
    const tabTitle = t([`${prefix}.tabs.${tabName}.title`, `tabs.${pageName}.labels.${tabName}`], { default: '' });
    pageTitle += tabTitle ? ` - ${tabTitle}` : '';
  }

  return {
    description: t([`${prefix}.meta.description`, 'app.meta.description']),
    title: `${commonTitle}${pageTitle ? ` - ${pageTitle}` : ''}`,
  };
}

export function getPagePermissionScopes() {
  return Object.fromEntries(
    Object.entries(PAGES).map(([key, page]) => {
      const pageName = key as PageName;
      const permissionsScopes = new Set();

      if ('permissionsScopes' in page) {
        for (const scope of page.permissionsScopes) {
          permissionsScopes.add(scope);
        }
      }

      for (const { permissionsScope } of getPageTabs(pageName)) {
        permissionsScopes.add(permissionsScope);
      }

      return [pageName, Array.from(permissionsScopes)];
    }),
  ) as Record<PageName, PermissionsScope[]>;
}

export function getPageTabs<const T extends PageName>(pageName: T) {
  return (isKeyInObject(pageName, PAGE_TABS) ? PAGE_TABS[pageName] : []) as T extends keyof PageTabs ? PageTabs[T] : [];
}

export function isHomePathname(pathname: string) {
  return typeof pathname === 'string' && ['', PAGES.home.path].includes(pathname);
}

export function getSanitizedRouteOverride(route: Route, override: RouteOverride) {
  const sanitizedOverride = {
    ...(!isSameLang(route, override) && { lang: override.lang }),
    ...(!isSamePathname(route, override) && { pathname: override.pathname }),
    ...(!isSameSearch(route, override) && { searchParams: override.searchParams }),
  } as const;

  return Object.keys(sanitizedOverride).length ? sanitizedOverride : null;
}

export function isRedirection(route: Route, override: RouteOverride) {
  return !(isSameLang(route, override) && isSamePathname(route, override) && isSameSearch(route, override));
}

function isSameLang(route: Route, override: RouteOverride) {
  return override.lang === undefined || override.lang === route.lang;
}

function isSamePathname(route: Route, override: RouteOverride) {
  return override.pathname === undefined || override.pathname === route.pathname;
}

function isSameSearch(route: Route, override: RouteOverride) {
  return override.searchParams === undefined || override.searchParams?.toString() === route.searchParams?.toString();
}
