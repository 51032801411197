export function getDeploymentConfig() {
  return {
    appName: process.env.BUILD_INFO_APPNAME || process.env.APP_NAME || '',
    branch: process.env.BUILD_INFO_BRANCH || '',
    commitHash: process.env.BUILD_INFO_COMMIT_HASH || '',
    commitHashShort: process.env.BUILD_INFO_COMMIT_HASH_SHORT || '',
    date: process.env.BUILD_INFO_DATE || '',
    environment: process.env.BUILD_INFO_ENVIRONMENT || '',
    host: process.env.VIRTUAL_HOST || '',
    npmPackageName: process.env.npm_package_name || '',
    npmPackageVersion: process.env.npm_package_version || '',
    path: process.env.INGRESS_PATH || '',
    version: process.env.BUILD_INFO_VERSION || '',
  } as const;
}
