import i18next from 'i18next';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

import { getWindow } from '@sbiz/util-browser';

import { parsePathname, Route, RouteOverride } from '../common';
import { getSanitizedRouteOverride } from '../common/pages';
import { useGetRedirectionUrl } from './useGetRedirectionUrl';

export function useRoute() {
  const fullPathname = usePathname();
  const { lang, pathname } = parsePathname(fullPathname);
  const getRedirectionUrl = useGetRedirectionUrl();
  const router = useRouter();
  const searchParams = useSearchParams();

  const setRoute = useCallback(
    (override: RouteOverride, options?: { isPush?: boolean; isShallow?: boolean }) => {
      const route = { lang, pathname, searchParams };
      const sanitizedOverride = getSanitizedRouteOverride(route, override);

      if (!sanitizedOverride) {
        return;
      }

      if (sanitizedOverride.lang) {
        i18next.changeLanguage(sanitizedOverride.lang);
      }

      const url = getRedirectionUrl(sanitizedOverride);
      if (options?.isShallow) {
        const window = getWindow('Shallow routing');
        options?.isPush ? window?.history.pushState({}, '', url) : window?.history.replaceState({}, '', url);
      } else {
        options?.isPush ? router.push(url) : router.replace(url);
      }
    },
    [getRedirectionUrl, lang, pathname, router, searchParams],
  );

  return [{ lang, pathname, searchParams } satisfies Route, setRoute] as const;
}
