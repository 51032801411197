// no-dd-sa:no-unnecessary-class
import { ApiError } from './ApiError';

export class Console {
  static apiError(apiError: ApiError) {
    const { code, details, status } = apiError;
    // no-dd-sa:typescript-best-practices/no-console
    console.error(apiError);
    // no-dd-sa:typescript-best-practices/no-console
    console.error({ status, code, ...(details && { details }) });
  }

  static debug(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.debug(...args);
  }

  static log(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.log(...args);
  }

  static error(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.error(...args);
  }

  static warn(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.warn(...args);
  }
}
