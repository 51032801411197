import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

import { API_RESOURCES, ResourceType } from '../resources';
import { ClearCacheOptions, ClearCacheResourceTypeParam } from '../types';

export function useClearCache() {
  const { mutate } = useSWRConfig();

  return useCallback(
    (resourceType?: ClearCacheResourceTypeParam, options?: ClearCacheOptions) => {
      mutate((key) => !resourceType || (typeof key === 'string' && isMutated(key, resourceType, options)), undefined, {
        revalidate: !options?.isRevalidationDisabled,
      });
    },
    [mutate],
  );
}

function isMatchingKey(key: string, resourceKey: string, options?: ClearCacheOptions) {
  if (resourceKey in API_RESOURCES) {
    if (options?.omit?.some((exclusion) => new RegExp(exclusion).test(key))) {
      return false;
    }

    const pathRegex = new RegExp(
      `${API_RESOURCES[resourceKey as ResourceType].path}${options?.path ? `/${options.path}` : ''}`,
    );
    return pathRegex.test(key);
  }

  return false;
}

function isMutated(key: string, resourceType: ClearCacheResourceTypeParam, options?: ClearCacheOptions) {
  const resourceTypes = Array.isArray(resourceType) ? resourceType : [resourceType];

  return resourceTypes.some(
    (resourceType) => isMatchingKey(key, resourceType, options) || isMatchingKey(key, `${resourceType}Admin`, options),
  );
}
