import { UNKNOWN_API_ERROR_KEY } from './constants';
import { ApiErrorOptions } from './types';

const ERROR_CODES = new Map<number, string>([
  [400, 'BAD_REQUEST'],
  [401, 'UNAUTHORIZED'],
  [403, 'FORBIDDEN'],
  [404, 'NOT_FOUND'],
  [409, 'CONFLICT'],
  [429, 'TOO_MANY_REQUEST'],
  [500, 'INTERNAL_SERVER_ERROR'],
  [502, 'BAD_GETAWAY'],
  [503, 'SERVICE_UNAVAILABLE'],
]);

export class ApiError extends Error {
  cause?: unknown;
  code: string;
  details?: Record<string, unknown>;
  messageKeys: string[];
  status: number;

  constructor(message: string, options?: ApiErrorOptions) {
    super(message);

    this.cause = options?.cause;
    this.code = options?.code ?? getErrorCode(options?.status);
    this.details = options?.details;
    this.status = options?.status ?? 500;

    this.messageKeys = [UNKNOWN_API_ERROR_KEY];
    if (options?.messageKeys?.length) {
      this.messageKeys.unshift(...options.messageKeys);
    }
  }
}

function getErrorCode(status?: number) {
  if (status) {
    if (ERROR_CODES.has(status)) {
      return ERROR_CODES.get(status) as string;
    }

    if (status >= 500) {
      return 'SERVER_ERROR';
    }

    if (status >= 400) {
      return 'CLIENT_ERROR';
    }
  }

  return 'UNKNOWN';
}
