import { SvgIcon, SvgIconProps } from '@mui/material';

export function StatementPayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(0.5,0,0,0.5,0.5,0)">
        <path d="m 18,20 h 20 v 4 H 18 Z m 0,8 h 20 v 4 H 18 Z m 0,8 h 20 v 4 H 18 Z M 34.83,0 H 10 v 2 c 2.25,0 4.31,0.75 5.98,2 H 30 V 16 H 42 V 44 H 14 V 21.16 C 12.77,21.7 11.42,22 10,22 V 48 H 46 V 11.17 Z M 34,12 V 4.83 L 41.17,12 Z" />
        <path
          d="M 15.98,4 C 14.31,2.75 12.24,2 10,2 4.48,2 0,6.48 0,12 c 0,5.52 4.48,10 10,10 1.42,0 2.77,-0.3 4,-0.84 C 17.53,19.62 20,16.1 20,12 20,8.72 18.42,5.82 15.98,4 Z m 0.08,5.73 -7.73,7.73 -4.39,-4.39 c -0.59,-0.59 -0.59,-1.54 0,-2.12 0.59,-0.58 1.54,-0.59 2.12,0 l 2.27,2.27 5.61,-5.61 c 0.59,-0.59 1.54,-0.59 2.12,0 0.58,0.59 0.59,1.54 0,2.12 z"
          fill="#00cea0"
        />
      </g>
    </SvgIcon>
  );
}
