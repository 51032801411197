import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

import { DEFAULT_LANG } from '@sbiz/common';

import { parsePathname, RouteOverride } from '../common';
import { PAGES } from '../common/pages';

export function useGetRedirectionUrl() {
  const fullPathname = usePathname();
  const searchParams = useSearchParams();

  return useCallback(
    (newRoute: RouteOverride) => {
      const { lang, pathname } = parsePathname(fullPathname);

      let url = '/';

      if (newRoute.lang === null) {
        url += DEFAULT_LANG;
      } else {
        url += newRoute.lang ?? lang ?? DEFAULT_LANG;
      }

      if (newRoute.pathname === null) {
        url += PAGES.home.path;
      } else {
        url += newRoute.pathname ?? pathname;
      }

      if (newRoute.searchParams !== null) {
        const newSearchParams = newRoute.searchParams ?? searchParams;
        if (newSearchParams.size) {
          url += `?${newSearchParams?.toString()}`;
        }
      }

      return url;
    },
    [fullPathname, searchParams],
  );
}
