import { isObjectLitteral, safeJSONParse } from '@sbiz/util-common';

import { ApiError } from './ApiError';
import { Console } from './Console';
import { StorageCache, StorageType } from './types';

const STORAGE_CACHE: StorageCache = { local: new Map(), session: new Map() };

const DATA_TYPES = {
  csv: { mediaType: 'text', encoding: 'utf-8', extension: 'csv' },
  pdf: { mediaType: 'application', encoding: 'base64', extension: 'pdf' },
} as const;
type DataType = keyof typeof DATA_TYPES;

export function downloadFile(content: string, type: DataType, filename?: string) {
  const anchor = document.createElement('a');

  const { mediaType, encoding, extension } = DATA_TYPES[type];
  const dataUrl = encodeURI(`data:${mediaType}/${extension};${encoding},${content}`);
  anchor.setAttribute('href', dataUrl);
  anchor.setAttribute('download', `${filename ?? 'export'}.${extension}`);

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export function downloadCsv(csvData: string, filename?: string) {
  return downloadFile(csvData, 'csv', filename);
}

export function downloadPdf(pdfData: string, filename?: string) {
  return downloadFile(pdfData, 'pdf', filename);
}

export function getStorageItem<T>(key: string, type: StorageType = 'session') {
  if (!isInBrowser(`Trying to access the browser's storage\nkey: '${key}'`)) {
    return null;
  }

  if (!key) {
    return null;
  }

  const storage = type === 'local' ? localStorage : sessionStorage;
  const cache = STORAGE_CACHE[type];

  const serialized = storage.getItem(key);
  if (!serialized) {
    return null;
  }

  const cached = cache.get(key);
  const parsed = safeJSONParse<T>(serialized);

  if (isObjectLitteral(parsed) && cached?.serialized === serialized) {
    return cached.parsed as T;
  }

  cache.set(key, { serialized, parsed });
  return parsed as T;
}

export function getStorageObject<T extends object>(key: string, type?: StorageType) {
  const storageItem = getStorageItem<T>(key, type);
  return isObjectLitteral(storageItem) ? storageItem : null;
}

export function removeStorageItem(key: string, type: StorageType = 'session') {
  if (isInBrowser(`Trying to remove a key from the browser's storage\nkey: '${key}'`)) {
    const storage = type === 'local' ? localStorage : sessionStorage;
    const cache = STORAGE_CACHE[type];

    storage.removeItem(key);
    cache.delete(key);
  }
}

export function setStorageItem(key: string, value: unknown, type?: StorageType, options?: { isOverwrite?: boolean }) {
  if (isInBrowser(`Trying to edit a key in the browser's storage\nkey: '${key}', value:${value}`)) {
    const storage = type === 'local' ? localStorage : sessionStorage;

    if (options?.isOverwrite || !isObjectLitteral(value)) {
      storage.setItem(key, JSON.stringify(value));
      return;
    }

    const storageObject = getStorageObject<typeof value>(key, type);
    const serialized = JSON.stringify({ ...storageObject, ...value });
    storage.setItem(key, serialized);
  }
}

export function getLocalStorageItem<T>(key: string) {
  return getStorageItem<T>(key, 'local');
}

export function getLocalStorageObject<T extends object>(key: string) {
  return getStorageObject<T>(key, 'local');
}

export function getWindow(message?: string) {
  if (globalThis.window) {
    return globalThis.window;
  }

  Console.warn(message ? `Server-side: ${message}` : 'Trying to access window server-side');
}

export function isInBrowser(message?: string) {
  return Boolean(getWindow(message));
}

export function removeLocalStorageItem(key: string) {
  return removeStorageItem(key, 'local');
}

export function setLocalStorageItem(key: string, value: unknown, options?: { isOverwrite?: boolean }) {
  return setStorageItem(key, value, 'local', options);
}

export function getSessionStorageItem<T>(key: string) {
  return getStorageItem<T>(key);
}

export function getSessionStorageObject<T extends object = Record<string, unknown>>(key: string) {
  return getStorageObject<T>(key);
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

export function removeSessionStorageItem(key: string) {
  return removeStorageItem(key);
}

export function setSessionStorageItem(key: string, value: unknown, options?: { isOverwrite?: boolean }) {
  return setStorageItem(key, value, 'session', options);
}

export { ApiError, Console };
