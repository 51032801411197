import { createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { Lang } from '@sbiz/common';

import { CONFIG } from '../constants';

export async function getServerTranslation(lang: Lang) {
  const i18n = await getI18nextInstance(lang);
  return { i18n, t: i18n.getFixedT(lang) };
}

async function getI18nextInstance(lng?: Lang) {
  const instance = createInstance();

  await instance
    .use(initReactI18next)
    .use(resourcesToBackend((lang: Lang) => import(`../locales/${lang}/common.json`)))
    .init({ ...CONFIG, lng });

  return instance;
}
