import { useCallback } from 'react';

import { useRoute } from '../../../hooks/useRoute';
import { PAGES } from '../../pages';
import { useClearAppData } from './useClearAppData';

export function useLogout() {
  const clearAppData = useClearAppData();
  const [, setRoute] = useRoute();

  const logout = useCallback(() => {
    clearAppData();
    setRoute({ pathname: PAGES.login.path, searchParams: null });
  }, [clearAppData, setRoute]);

  return logout;
}
