import { SvgIcon, SvgIconProps } from '@mui/material';

export function CompanyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="scale(0.5)">
        <path d="m 10,32 h 4 v 4 h -4 z m 0,-8 h 4 v 4 h -4 z m 8,0 h 4 v 4 h -4 z m -8,-8 h 4 v 4 h -4 z m 8,0 h 4 v 4 h -4 z m 0,-8 h 4 v 4 h -4 z m 8,16 h 4 v 4 h -4 z m 0,-8 h 4 v 4 h -4 z m 0,-8 h 4 v 4 h -4 z m 12,8 V 0 H 10 V 8 H 2 V 48 H 22 V 38 h 4 V 48 H 46 V 16 Z m -4,2 V 44 H 30 V 34 H 18 V 44 H 6 V 12 h 8 V 4 h 20 z m 8,26 H 38 V 20 h 4 z" />
      </g>
    </SvgIcon>
  );
}
